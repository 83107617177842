import React, { useCallback } from "react";

import { useLoaderData } from "react-router-dom";

import { useTelegram } from "../hooks/useTelegram";
import "../App.css";

import Fields from "../components/Fields";

const FeedbackPage = () => {
  const { tg } = useTelegram();
  const { servicedesk, requesttype, customer, fields, isAnonymous } =
    useLoaderData();
  const lang = customer.language || "en";

  const onBackPressed = useCallback(() => {
    tg.close();
  }, [tg]);

  return (
    <Fields
      servicedesk={servicedesk}
      requesttype={requesttype}
      fields={fields}
      isAnonymous={isAnonymous}
      lang={lang}
      onBackPressed={onBackPressed}
    />
  );
};

export default FeedbackPage;

import { useRouteError } from "react-router-dom";
import EmptyState from "@atlaskit/empty-state";
import Button from "@atlaskit/button/standard-button";

export default function ErrorPage() {
  const error = useRouteError();

  const header = error.status
    ? `${error.status} ${error.statusText}`
    : "Sorry, an unexpected error has occurred";

  const description = error.message || error.data || "Unknown error";

  return (
    <EmptyState
      header={header}
      description={description}
      headingLevel={1}
      primaryAction={
        <Button appearance="link" href="/">
          Go home
        </Button>
      }
    />
  );
}

const TXT_WELCOME = {
  en: "Welcome",
  ru: "Добро пожаловать",
};

const TXT_SELECT_DEPARTMENT = {
  en: "Select a department",
  ru: "Выберите департамен",
};

const TXT_SELECT_REQUEST_TYPE = {
  en: "Select request type",
  ru: "Выберите тип запроса",
};

const TXT_VALUE_REQUIRED = {
  en: "This field is required",
  ru: "Это поле обязательное",
};

const TXT_SEND = {
  en: "Send",
  ru: "Отправить",
};

const TXT_CANCEL = {
  en: "Cancel",
  ru: "Отменить",
};

const TXT_BACK = {
  en: "Back",
  ru: "Назад",
};

const TXT_INVALID_VALUE = {
  en: "Invalid value",
  ru: "Недопустимое значен",
};

const TXT_NO_FIELDS_AVAILABLE = {
  en: "No fields available",
  ru: "Нет доступных полей",
};

const TXT_DRAG_AND_DROP = {
  en: "Drag and drop files here",
  ru: "Перетащите файлы сюда",
};

const TXT_OR = {
  en: "or",
  ru: "или",
};

const TXT_BROWSE = {
  en: "Browse",
  ru: "Выбрать",
};

export {
  TXT_WELCOME,
  TXT_SELECT_DEPARTMENT,
  TXT_SELECT_REQUEST_TYPE,
  TXT_VALUE_REQUIRED,
  TXT_SEND,
  TXT_CANCEL,
  TXT_BACK,
  TXT_INVALID_VALUE,
  TXT_NO_FIELDS_AVAILABLE,
  TXT_DRAG_AND_DROP,
  TXT_OR,
  TXT_BROWSE,
};

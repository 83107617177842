import React from "react";

import "../App.css";

import { CustomItem, MenuGroup, Section } from "@atlaskit/menu";
import { Link, useLoaderData } from "react-router-dom";

import PageHeader from "@atlaskit/page-header";
import { TXT_SELECT_DEPARTMENT, TXT_WELCOME } from "../i8n";

const ServiceDeskPage = () => {
  const { servicedesks, customer } = useLoaderData();
  const lang = customer.language || "en";

  return (
    <MenuGroup>
      <div className="HeaderWrapper">
        <PageHeader>
          {`${TXT_WELCOME[lang]}, ${customer.first_name} ${customer.last_name}`}
        </PageHeader>
      </div>
      <Section title={TXT_SELECT_DEPARTMENT[lang]}>
        {servicedesks.values.map((servicedesk) => (
          <CustomItem
            component={Link}
            to={`/servicedesk/${servicedesk.id}/requesttype`}
            state={{ servicedesk, customer }}
            key={servicedesk.id}
            shouldDescriptionWrap={true}
          >
            {servicedesk.projectName}
          </CustomItem>
        ))}
      </Section>
    </MenuGroup>
  );
};

export default ServiceDeskPage;

import "./App.css";
import FieldPage from "./pages/FieldPage";
import ServiceDeskPage from "./pages/ServiceDeskPage";
import RequesttypePage from "./pages/RequesttypePage";
import ErrorPage from "./pages/ErrorPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  feedbackPageLoader,
  requesttypePageLoader,
  fieldPageLoader,
  serviceDeskPageLoader,
  hotlinePageLoader,
} from "./loaders";
import { useEffect } from "react";
import { useTelegram } from "./hooks/useTelegram";
import { RootPage } from "./pages/RootPage";
import { Loading } from "./components/Loading";
import EmptyState from "@atlaskit/empty-state";
import LockClosedImage from "./LockClosed.png";
import FeedbackPage from "./pages/FeedbackPage";
import HotlinePage from "./pages/HotlinePage";

function App() {
  const { tg, initData } = useTelegram();

  useEffect(() => {
    tg.ready();
    tg.expand();
  }, [tg]);

  if (!tg || !initData)
    return (
      <EmptyState
        headingLevel={1}
        header="You don't have access to this site"
        description="Make sure you open the page from the Telegram app. If so, ask the IT department to check if your data is in the system."
        imageUrl={LockClosedImage}
      />
    );

  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootPage />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <ServiceDeskPage />,
          loader: serviceDeskPageLoader,
        },
        {
          path: "/servicedesk/:serviceDeskId/requesttype",
          element: <RequesttypePage />,
          loader: requesttypePageLoader,
        },
        {
          path: "/servicedesk/:serviceDeskId/requesttype/:requestTypeId/field",
          element: <FieldPage />,
          loader: fieldPageLoader,
        },
      ],
    },
    {
      path: "/feedback/:type",
      element: <FeedbackPage />,
      loader: feedbackPageLoader,
      errorElement: <ErrorPage />,
    },
    {
      path: "/hotline/:type",
      element: <HotlinePage />,
      loader: hotlinePageLoader,
      errorElement: <ErrorPage />,
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} fallbackElement={<Loading />} />
    </div>
  );
}

export default App;

import React, { useCallback } from "react";

import { useLoaderData, useNavigate, useLocation } from "react-router-dom";

import "../App.css";

import Fields from "../components/Fields";

const FieldPage = () => {
  const navigate = useNavigate();
  const {
    state: { servicedesk, requesttype, customer },
  } = useLocation();
  const lang = customer.language || "en";

  const { fields } = useLoaderData();

  const onBackPressed = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Fields
      servicedesk={servicedesk}
      requesttype={requesttype}
      fields={fields}
      isAnonymous={false}
      lang={lang}
      onBackPressed={onBackPressed}
    />
  );
};

export default FieldPage;

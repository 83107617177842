import Button from "@atlaskit/button";
import React from "react";
import { TXT_BROWSE, TXT_DRAG_AND_DROP, TXT_OR } from "../i8n";

const Banner = ({ onClick, onDrop, lang }) => {
  const handleDragOver = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    onDrop(ev.dataTransfer.files);
  };

  return (
    <div
      className="DropZone"
      onClick={onClick}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <span>{TXT_DRAG_AND_DROP[lang]}</span>
      <span>{TXT_OR[lang]}</span>
      <Button>{TXT_BROWSE[lang]}</Button>
    </div>
  );
};

const DropZone = ({ onChange, accept = ["*"], lang, required, name }) => {
  const inputRef = React.useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleChange = (ev) => {
    onChange(ev.target.files);
  };

  const handleDrop = (files) => {
    onChange(files);
  };

  return (
    <div className="DropZoneContainer">
      <Banner onClick={handleClick} onDrop={handleDrop} lang={lang} />
      <input
        style={{ opacity: 0, height: "1px", pointerEvents: "none" }}
        name={name}
        type="file"
        aria-label="Choose File"
        ref={inputRef}
        multiple="multiple"
        onChange={handleChange}
        accept={accept.join(",")}
        required={required}
      />
    </div>
  );
};

export { DropZone };

import React, { useCallback } from "react";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import CheckCircleOutlineIcon from "@atlaskit/icon/glyph/check-circle-outline";
import JiraFailedBuildStatusIcon from "@atlaskit/icon/glyph/jira/failed-build-status";
import Spinner from "@atlaskit/spinner";
import Button from "@atlaskit/button";

const FileListItem = ({ name, id, onClear, uploadComplete, failed }) => {
  const handleClear = useCallback(() => {
    onClear(id);
  }, [id, onClear]);

  return (
    <li className="FileListItem">
      {uploadComplete ? (
        failed ? (
          <span role="img" className="FileListItemFail">
            <JiraFailedBuildStatusIcon size="small" />
          </span>
        ) : (
          <span role="img" className="FileListItemCheck">
            <CheckCircleOutlineIcon size="small" />
          </span>
        )
      ) : (
        <span role="img" className="FileListItemCheck">
          <Spinner size="small" />
        </span>
      )}
      <span style={failed ? { textDecoration: "line-through" } : {}}>
        {name}
      </span>
      <Button
        className="FileListItemClear"
        role="button"
        aria-label="remove file"
        spacing="none"
        appearance="subtle"
        isDisabled={!uploadComplete}
        onClick={handleClear}
      >
        <CrossIcon size="small" />
      </Button>
    </li>
  );
};

const FileList = ({ files, onClear }) => {
  return (
    <ul className="FileList">
      {files.map(({ file, id, uploaded, failed }) => (
        <FileListItem
          name={file.name}
          key={id}
          id={id}
          onClear={onClear}
          uploadComplete={uploaded}
          failed={failed}
        />
      ))}
    </ul>
  );
};

export { FileList };

import React, { useEffect, useCallback } from "react";

import { CustomItem, MenuGroup, Section } from "@atlaskit/menu";
import {
  Link,
  useLoaderData,
  useNavigate,
  useLocation,
} from "react-router-dom";

import PageHeader from "@atlaskit/page-header";
import EmptyState from "@atlaskit/empty-state";

import { useTelegram } from "../hooks/useTelegram";
import "../App.css";
import { TXT_SELECT_REQUEST_TYPE } from "../i8n";

const RequesttypePage = () => {
  const { requesttypes } = useLoaderData();
  const navigate = useNavigate();
  const {
    state: { servicedesk, customer },
  } = useLocation();
  const lang = customer.language || "en";

  const { tg } = useTelegram();

  useEffect(() => {
    tg.BackButton.show();
  }, []);

  const onBackPressed = useCallback(() => {
    navigate(-1);
    tg.BackButton.hide();
  }, []);

  useEffect(() => {
    tg.onEvent("backButtonClicked", onBackPressed);
    return () => {
      tg.offEvent("backButtonClicked", onBackPressed);
    };
  }, [onBackPressed]);

  const list = requesttypes.values.filter((e) => e.canCreateRequest);

  return (
    <MenuGroup>
      <div className="HeaderWrapper">
        <PageHeader>{servicedesk.projectName}</PageHeader>
      </div>
      <Section title={TXT_SELECT_REQUEST_TYPE[lang]}>
        {list.length > 0 ? (
          list.map(
            (requesttype) =>
              requesttype.groupIds?.length > 0 && (
                <CustomItem
                  component={Link}
                  to={`/servicedesk/${servicedesk.id}/requesttype/${requesttype.id}/field`}
                  state={{ servicedesk, customer, requesttype }}
                  key={requesttype.id}
                  description={requesttype.description}
                  shouldDescriptionWrap={true}
                >
                  {requesttype.name}
                </CustomItem>
              )
          )
        ) : (
          <EmptyState header="No requests available"></EmptyState>
        )}
      </Section>
    </MenuGroup>
  );
};

export default RequesttypePage;
